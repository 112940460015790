// src/firebaseInit.js
import firebase from "firebase";
import 'firebase/firestore'

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAHM7fKsHrgI9iisGy2HSA0N0RBNvsMKsk",
    authDomain: "eventregistration-ed56e.firebaseapp.com",
    projectId: "eventregistration-ed56e",
    storageBucket: "eventregistration-ed56e.appspot.com",
    messagingSenderId: "281885944618",
    appId: "1:281885944618:web:29cf9fb29e1b8fac9a4c53",
    measurementId: "G-EVF3L247D7"
};
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
// Initialize Firebase
//export default firebase.initializeApp(firebaseConfig);
export default db;